@use '@angular/material' as mat;

@include mat.core();

@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import "https://fonts.googleapis.com/icon?family=Material+Icons";

.elevated {
  @include mat.elevation-transition;
  @include mat.elevation(2);
  @media (any-pointer: fine) and (any-hover: hover) {
    &:not(.nohover):hover {
      @include mat.elevation(8);
    }
  }
}
// Customize the entire app. Change :root to your selector if you want to scope the styles.
:root {
  @include mat.sidenav-overrides((
    container-background-color: white,
    content-background-color: white,
  ));
}
